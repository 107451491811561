import { useState } from "react"
import styled from "styled-components"

import { PieChart } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/PieChart"
import { useFetchOrganizationAnalyticsStates } from "src/data/organizations/queries/analyticsQueries"
import { TOrganizationAnalyticsStateQueryParams } from "src/data/organizations/types/organizationTypes"
import { Container } from "src/ui/Container/Container"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

type TStateFilter = {
  value: TOrganizationAnalyticsStateQueryParams["state_group"] | null
  label: string
}

export function ParadiseOrganizationAnalyticsStates({
  orgId,
}: {
  orgId: string
}) {
  const filterValues = [
    "disturbance",
    "indoor_climate",
    "guests",
    "features",
    "fleet_health",
  ]
  const filters = filterValues.map((value) => ({
    value,
    // Convert snake_case to "Title Case"
    label: value.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
  }))
  const [filter, setFilter] = useState<TStateFilter>(filters[0] as TStateFilter)

  const fetchStates = useFetchOrganizationAnalyticsStates({
    params: {
      state_group: filter.value ?? "disturbance",
    },
    orgId,
  })

  if (fetchStates.isError) {
    return (
      <ParadiseOrganizationAnalyticsStatesLayout
        filter={filter}
        setFilter={setFilter}
        filters={filters}
      >
        <MBanner type="error">Error fetching states</MBanner>
      </ParadiseOrganizationAnalyticsStatesLayout>
    )
  }

  if (fetchStates.isLoading) {
    return (
      <ParadiseOrganizationAnalyticsStatesLayout
        filter={filter}
        setFilter={setFilter}
        filters={filters}
      >
        <MSkeleton
          variant="rect"
          width="100%"
          height="calc(200px - 2rem)"
          style={{ marginTop: spacing.M, marginBottom: spacing.M }}
        />
      </ParadiseOrganizationAnalyticsStatesLayout>
    )
  }

  // Remap the data to the format expected by the PieChart component
  const stateData = fetchStates.data.states.map((state) => ({
    name: state.name,
    data: state.data.map((d) => ({
      name: d.label,
      y: d.value,
    })),
  }))

  return (
    <ParadiseOrganizationAnalyticsStatesLayout
      filter={filter}
      setFilter={setFilter}
      filters={filters}
    >
      <StatesWrapper>
        {stateData.map((state) => (
          <PieChart
            key={state.name}
            title={state.name}
            data={state.data}
            size={120}
            showLegend={true}
          />
        ))}
      </StatesWrapper>
    </ParadiseOrganizationAnalyticsStatesLayout>
  )
}

function ParadiseOrganizationAnalyticsStatesLayout({
  children,
  filter,
  setFilter,
  filters,
}: {
  children: React.ReactNode
  filter: TStateFilter
  setFilter: (filter: TStateFilter) => void
  filters: { value: string; label: string }[]
}) {
  return (
    <Wrapper>
      <StateFilter
        filter={filter}
        setFilter={setFilter}
        availableFilterGroups={filters}
      />
      {children}
    </Wrapper>
  )
}

function StateFilter({
  filter,
  setFilter,
  availableFilterGroups,
}: {
  filter: TStateFilter
  setFilter: (filter: TStateFilter) => void
  availableFilterGroups: { value: string; label: string }[]
}) {
  return (
    <DropdownSelect
      label={filter.label ?? "State"}
      options={availableFilterGroups}
      placeholder="Search"
      selectedValue={filter.value}
      onChange={({ option }) => {
        setFilter({
          value:
            option.value as TOrganizationAnalyticsStateQueryParams["state_group"],
          label: option.label,
        })
      }}
      debounceDelay={0}
    />
  )
}

const StatesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  @container (min-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: ${spacing.M};

  height: fit-content;
`

const Wrapper = styled(Container)`
  margin-bottom: ${spacing.M};
  display: grid;
  gap: ${spacing.M};
`
