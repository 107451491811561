import { ParadiseTable } from "src/components/Paradise/ParadiseTable"

export function ParadiseOrganizationAnalyticsTop10ByEvents() {
  const fakeData = [
    {
      homeName: "250 Fell street",
      count: 10,
    },
    {
      homeName: "123 Main street",
      count: 23,
    },
  ]

  const headers = [
    <div key={"home-name"}>Home name</div>,
    <div key={"count"}>Number of events</div>,
  ]
  const rows = fakeData.map(({ homeName, count }) => (
    <>
      <div>{homeName}</div>
      <div>{count}</div>
    </>
  ))

  return (
    <div>
      <EventFilter />
      <ParadiseTable header={headers} rows={rows} />
    </div>
  )
}

function EventFilter() {
  return <div>TODO add some nice event filter here</div>
}
