import { useState } from "react"

import { Pager } from "src/components/Pager/Pager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"
import { formatUtcDate } from "src/utils/l10n"

const LIMIT = 15

export function ParadiseOrganizationHomeGroups({ orgId }: { orgId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)

  const fetchHomeGroups = useFetchHomeGroups({
    orgId,
    filter: {
      offset,
      limit: LIMIT,
    },
  })

  const headers = [
    <div key={"id"}>Id</div>,
    <div key={"name"}>Name</div>,
    <div key={"created-at"}>Created at</div>,
  ]

  const rows =
    fetchHomeGroups.data?.homegroups.map((homeGroup) => (
      <>
        <InternalLink
          to={Routes.ParadiseHomeGroup.location({
            orgId,
            homeGroupId: homeGroup.id,
          })}
        >
          {homeGroup.id}
        </InternalLink>
        <div>{homeGroup.name}</div>
        <div>{formatUtcDate({ date: homeGroup.created_at })}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Homegorups" />
      <ParadiseTable
        header={headers}
        rows={rows}
        onRowClick={(index) => {
          const homeGroupId = fetchHomeGroups.data?.homegroups[index]?.id

          if (!homeGroupId) {
            throw new Error("Home group id is not found when clicking on row")
          }

          navigate(Routes.ParadiseHome.location(homeGroupId))
        }}
      />
      <Pager
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchHomeGroups.data?.paging.total_count}
      />
    </div>
  )
}
