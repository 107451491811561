import { useState } from "react"
import styled from "styled-components"

import { subDays } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrganizationAnalyticsEventGraph } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsEventsGraph"
import { ParadiseOrganizationAnalyticsStates } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsStates"
import { ParadiseOrganizationAnalyticsTop10ByMetric } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsTop10ByMetric"
import { ParadiseOrganizationAnalyticsTop10ByEvents } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalyticsTop10List"
import { DateRangePicker } from "src/ui/DateRangePicker/DateRangePicker"
import { spacing } from "src/ui/spacing"

export function ParadiseOrganizationAnalytics({ orgId }: { orgId: string }) {
  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  })
  function handleDateRangeChange({ startDate, endDate }: IGraphDateRange) {
    setDateRange({ startDate, endDate })
  }

  return (
    <div>
      <DatePickerWrapper>
        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDateRangeChange={handleDateRangeChange}
          dateRangeProps={{
            minBookingDays: 2,
          }}
          defaultPresetKey="DAY"
          calendarPlacement="top-end"
        />
      </DatePickerWrapper>

      <ParadiseDetailsSection title="States">
        <ParadiseOrganizationAnalyticsStates orgId={orgId} />
      </ParadiseDetailsSection>
      <ParadiseDetailsSection title="Event frequency">
        <ParadiseOrganizationAnalyticsEventGraph
          orgId={orgId}
          from={dateRange.startDate}
          to={dateRange.endDate}
        />
      </ParadiseDetailsSection>
      <ParadiseDetailsSection title="Top 10 homes by event types">
        <ParadiseOrganizationAnalyticsTop10ByEvents />
      </ParadiseDetailsSection>
      <ParadiseDetailsSection title="Top 10 homes by metric">
        <ParadiseOrganizationAnalyticsTop10ByMetric />
      </ParadiseDetailsSection>
    </div>
  )
}

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${spacing.M};
`
