import { ParadiseTable } from "src/components/Paradise/ParadiseTable"

export function ParadiseOrganizationAnalyticsTop10ByMetric() {
  const fakeData = [
    {
      homeName: "250 Fell street",
      value: 34,
    },
    {
      homeName: "123 Main street",
      value: 23,
    },
  ]

  const headers = [
    <div key={"home-name"}>Home name</div>,
    <div key={"count"}>Value</div>,
  ]
  const rows = fakeData.map(({ homeName, value }) => (
    <>
      <div>{homeName}</div>
      <div>{value}</div>
    </>
  ))

  return (
    <div>
      <MetricSelector />
      <ParadiseTable header={headers} rows={rows} />
    </div>
  )
}

function MetricSelector() {
  return <div>TODO add some nice metric selector here</div>
}
