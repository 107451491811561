import { useMemo, useState } from "react"
import styled from "styled-components"

import { endOfDay, startOfDay } from "date-fns"

import { mapFilterGroupsToHomeEventTypes } from "src/data/events/logic/eventsLogic"
import { homeEventFilterOptions } from "src/data/events/logic/homeEventConstants"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { useFetchOrganizationAnalytics } from "src/data/organizations/queries/analyticsQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { BarChart } from "src/ui/Graphs/BarChart"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

/**
 * Function that takes a date object and formats it
 * to an ISO string in UTC without converting from the
 * local timezone.
 * @param date
 */
function dateToUTCISOString(localDate: Date) {
  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  )
  return utcDate.toISOString()
}

export function ParadiseOrganizationAnalyticsEventGraph({
  orgId,
  from,
  to,
}: {
  orgId: string
  from: Date
  to: Date
}) {
  const timezone = "utc" // Hardcode this to UTC since orgs can span multiple timezones
  const { _t } = useTranslate()
  const user = useGetUser()

  const availableFilterGroups = homeEventFilterOptions
    .map((e) => ({
      value: e.value,
      label: _t(e.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const [filter, setFilter] = useState<{
    value: THomeEventFilterGroup | null
    label: string
  }>({
    value: null,
    label: "Event type",
  })

  const eventTypeFilter = useMemo(() => {
    if (!filter.value) return []
    return mapFilterGroupsToHomeEventTypes([filter.value])
  }, [filter])

  const start = dateToUTCISOString(startOfDay(from))
  const end = dateToUTCISOString(endOfDay(to))

  const fetchAnalyticsEvents = useFetchOrganizationAnalytics({
    params: {
      from: start,
      to: end,
      event_type: eventTypeFilter.length
        ? eventTypeFilter.join(",")
        : undefined,
    },
    orgId,
  })

  if (fetchAnalyticsEvents.isError) {
    return (
      <div>
        <EventFilter
          filter={filter}
          setFilter={setFilter}
          availableFilterGroups={availableFilterGroups}
        />
        <div>Error fetching analytics events</div>
      </div>
    )
  }

  if (fetchAnalyticsEvents.isLoading) {
    return (
      <div>
        <EventFilter
          filter={filter}
          setFilter={setFilter}
          availableFilterGroups={availableFilterGroups}
        />
        <MSkeleton
          variant="rect"
          width="100%"
          height="calc(400px - 2rem)"
          style={{ marginTop: spacing.M, marginBottom: spacing.M }}
        />
      </div>
    )
  }

  // Remap the data to the format expected by the BarChart component
  const data: [number, number][] = fetchAnalyticsEvents.data.events.map((e) => [
    new Date(e.timestamp).getTime() / 1000,
    e.number_of_events,
  ])

  return (
    <Wrapper>
      <EventFilter
        filter={filter}
        setFilter={setFilter}
        availableFilterGroups={availableFilterGroups}
      />
      <BarChart
        data={data}
        timezone={timezone}
        clockType={user.clock_type ?? "24"}
        tooltip={{
          decimals: 0,
          unit: "Events",
        }}
      />
    </Wrapper>
  )
}

function EventFilter({
  filter,
  setFilter,
  availableFilterGroups,
}: {
  filter: { value: THomeEventFilterGroup | null; label: string }
  setFilter: (filter: {
    value: THomeEventFilterGroup | null
    label: string
  }) => void
  availableFilterGroups: { value: THomeEventFilterGroup; label: string }[]
}) {
  return (
    <DropdownSelect
      label={filter.label ?? "Event type"}
      options={availableFilterGroups}
      placeholder="Search"
      selectedValue={filter.value}
      onClearSelection={() => {
        setFilter({
          value: null,
          label: "Event type",
        })
      }}
      onChange={({ option }) => {
        setFilter({
          value: option.value,
          label: option.label,
        })
      }}
      debounceDelay={0}
    />
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
