import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseOrganizationActions } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationActions"
import { ParadiseOrganizationAnalytics } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAnalytics/ParadiseOrganizationAnalytics"
import { ParadiseOrganizationAPIClients } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationAPIClients/ParadiseOrganizationAPIClients"
import { ParadiseOrganizationDevices } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationDevices/ParadiseOrganizationDevices"
import { ParadiseOrganizationHomeGroups } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomeGroups/ParadiseOrganizationHomeGroups"
import { ParadiseOrganizationHomes } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationHomes/ParadiseOrganizationHomes"
import { ParadiseOrganizationMembers } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationMembers/ParadiseOrganizationMembers"
import { ParadiseOrganizationOverview } from "src/components/Paradise/ParadiseOrganizations/ParadiseOrganizationDetails/ParadiseOrganizationOverview/ParadiseOrganizationOverview"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchOrganization } from "src/data/organizations/queries/organizationQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function ParadiseOrganizationDetails({ orgId }: { orgId: string }) {
  const { t } = useTranslate()

  const fetchOrganization = useFetchOrganization({
    id: orgId,
  })

  useAddRecentlyViewedPage({
    title: fetchOrganization.data?.name,
    enitytId: fetchOrganization.data?.id,
    type: "Organization",
    enabled: fetchOrganization.isSuccess,
  })

  if (fetchOrganization.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchOrganization.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const organization = fetchOrganization.data

  return (
    <ParadiseDetailsLayout
      meta={
        <ParadiseDetailsHeading>{organization.name}</ParadiseDetailsHeading>
      }
      left={<ParadiseOrganizationOverview organization={organization} />}
      actions={<ParadiseOrganizationActions organization={organization} />}
      right={
        <Tabs
          labels={[
            {
              id: "general",
              label: "General",
            },
            {
              id: "analytics",
              label: "Analytics",
            },
          ]}
        >
          <TabPanel>
            <ParadiseOrganizationMembers orgId={organization.id} />
            <ParadiseOrganizationHomes orgId={organization.id} />
            <ParadiseOrganizationHomeGroups orgId={organization.id} />
            <ParadiseOrganizationAPIClients orgId={organization.id} />
            <ParadiseOrganizationDevices orgId={organization.id} />
          </TabPanel>
          <TabPanel>
            <ParadiseOrganizationAnalytics orgId={organization.id} />
          </TabPanel>
        </Tabs>
      }
      breadcrumbs={[
        {
          label: "Organizations",
          to: Routes.ParadiseOrganizations.location(),
        },
        {
          label: organization.id,
          to: Routes.ParadiseOrganization.location(organization.id),
        },
      ]}
      breadcrumbCopyValue={organization.id}
    />
  )
}
