import styled from "styled-components"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function ParadiseOrganizationDevices({ orgId: _ }: { orgId: string }) {
  return (
    <div>
      <ParadiseDetailsSection title="Devices" />
      <Wrapper>
        <MBanner type="warning" fullWidth>
          This API is not yet developed for Paradise
        </MBanner>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  margin-top: ${spacing.M};
`
