import styled from "styled-components"

import { backgroundGrayV2 } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TCSVColumn = {
  name: string
  description?: string
  required?: boolean
}

export function ParadiseCSVRequirements({
  columns,
}: {
  columns: TCSVColumn[]
}) {
  const headers = [
    <div key={"header"}>Header</div>,
    <div key={"description"}>Description</div>,
  ]

  const rows = columns.map((c) => (
    <>
      <div>
        <FormattedVariable>{c.name}</FormattedVariable>
      </div>
      <DescriptionWrapper>
        {c.description && <MText variant="body">{c.description}</MText>}
        <div>{c.required && <MBanner size="small">Required</MBanner>}</div>
      </DescriptionWrapper>
    </>
  ))

  return (
    <div>
      <GridTable
        header={headers}
        rows={rows}
        mobileRows={rows}
        minWidth={0}
        templateColumns="auto 1fr"
      />
    </div>
  )
}

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`

const FormattedVariable = styled.div`
  padding: 0 ${spacing.XS2};
  background: ${backgroundGrayV2};
  border-radius: 4px;
  display: inline-block;
`
