import { forwardRef } from "react"
import styled from "styled-components"

import { colorsLegacy, gray50, mColors } from "src/ui/colors"
import FileUploadIcon from "src/ui/icons/file-upload.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const ParadiseDropZone = forwardRef<
  HTMLInputElement,
  {
    onChange: (file: File | null) => void
    accept: string
    hint: string
    hasError: boolean
  }
>(function ParadiseDropZoneRef({ onChange, accept, hint, hasError }, ref) {
  return (
    <Box $hasError={hasError}>
      <InnerWrapper>
        <FileUploadIcon width={24} />
        <div>
          <MText variant="body">
            Drop file or <FilePickerButton>open file browser</FilePickerButton>
          </MText>
          {hint && (
            <MText variant="bodyS" color="secondary">
              {hint}
            </MText>
          )}
        </div>
      </InnerWrapper>

      <StyledInput
        ref={ref}
        type="file"
        accept={accept}
        onChange={(e) => {
          onChange(e.target.files?.item(0) ?? null)
        }}
      />
    </Box>
  )
})

const Box = styled.div<{ $hasError: boolean }>`
  margin-top: ${spacing.M};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed
    ${({ $hasError }) =>
      $hasError ? colorsLegacy.systemEmergencyForeground : mColors.divider};
  background: ${gray50}90;
  border-radius: 6px;
  padding-block: ${spacing.XL4};
  padding-inline: ${spacing.M};

  &:focus-within {
    outline: 2px solid ${colorsLegacy.systemInfoForeground};
    outline-offset: 2px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.S};
  text-align: center;
`

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`
const FilePickerButton = styled.span`
  color: ${colorsLegacy.primaryTint};

  ${Box}:hover & {
    text-decoration: underline;
  }
`
