import { useState } from "react"

import { ParadiseConfirmationDialog } from "src/components/Paradise/ParadiseConfirmationDialog"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { RequireRoles } from "src/components/RestrictedRoles/RequireRoles"
import { useDeleteOrganization } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { UserRoles } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"

export function ParadiseOrganizationActions({
  organization,
}: {
  organization: IOrganization
}) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)

  const { navigate } = useRouter()

  const deleteOrganization = useDeleteOrganization()

  function handleConfirm() {
    deleteOrganization.mutate(
      {
        orgId: organization.id,
      },
      {
        onSuccess: () => {
          navigate(Routes.ParadiseOrganizations.location())
        },
      }
    )
  }

  return (
    <RequireRoles roles={[UserRoles.SUPERADMIN]}>
      <ActionsWrapper>
        <MButton
          variant="subtle"
          color="destructive"
          size="small"
          onClick={() => setShowDeleteConfirmDialog(true)}
        >
          Delete organization
        </MButton>
      </ActionsWrapper>
      <ParadiseConfirmationDialog
        title="Delete organization"
        content={
          <>
            <MText>
              Please insert the name ({organization.name}) of the organization
              to confirm.
            </MText>
            <MBanner type="error">
              <MText variant="body" color="emergency">
                This action cannot be undone.
              </MText>
            </MBanner>
          </>
        }
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        confirmationText={organization.name}
        confirmLabel="Delete"
        onConfirm={handleConfirm}
        loading={deleteOrganization.isLoading}
        error={deleteOrganization.isError && "Could not delete organization"}
      />
    </RequireRoles>
  )
}
